<script>
	import percentageRowComponent from './percentageRowComponent.vue'
	import statsRowComponent from './statsRowComponent.vue'
	import { httpGet } from '../assets/js/utils.js'

	export default {
			props: ['fixture', 'selected_tab', 'limit'],
			components: {
				percentageRowComponent,
				statsRowComponent
			},
			watch: {
				limit: function() {
					this.getData()
				}
			},
			data() {
					return {
						goalsData: [],
						isLoading: false
					};
			},
			methods: {
				async getData() {
					try {
						this.isLoading = true;
						await this.$nextTick();
						this.goalsData = await httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/goals/${this.fixture}?limit=${this.limit}`)
					} catch (error) {
						// Catch Error if necessary?
					} finally {
						this.isLoading = false;
					}
				}
			},
			// TODO: make URL dynamic LIVE vs TEST; move this to method? see leagueComponent.vue
			created() {
				this.getData()
			}
	};
</script>


<template>
	<div class="crd-tab" id="crd-tab-goals" v-if="selected_tab == 'goals'">
		<!-- <div class="loading-card" v-if="isLoading">
			<img src="../assets/img/spinner.gif" class="spinner">
			<h2>Calculating goals...</h2>
		</div> -->
		<div class="goals-perc">
			<div class="greyheader">
				<p class="title">Last</p><p class="limit-indicator">{{this.limit}}</p><p>matches</p>
			</div>
			<div class="perc-row-holder" v-if="isLoading">
				<div class="perc-row">
					<div class="perc-indicator skeleton"></div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-home"></div>
					</div>
					<div class="perc-label">
						<p>BTTS</p>
					</div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-away"></div>
					</div>
					<div class="perc-indicator skeleton"></div>
				</div>
				<div class="perc-row">
					<div class="perc-indicator skeleton"></div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-home"></div>
					</div>
					<div class="perc-label">
						<p>BTTS & Win</p>
					</div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-away"></div>
					</div>
					<div class="perc-indicator skeleton"></div>
				</div>
				<div class="perc-row">
					<div class="perc-indicator skeleton"></div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-home"></div>
					</div>
					<div class="perc-label">
						<p>Over 0.5</p>
					</div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-away"></div>
					</div>
					<div class="perc-indicator skeleton"></div>
				</div>
				<div class="perc-row">
					<div class="perc-indicator skeleton"></div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-home"></div>
					</div>
					<div class="perc-label">
						<p>Over 1.5</p>
					</div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-away"></div>
					</div>
					<div class="perc-indicator skeleton"></div>
				</div>
				<div class="perc-row">
					<div class="perc-indicator skeleton"></div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-home"></div>
					</div>
					<div class="perc-label">
						<p>Over 2.5</p>
					</div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-away"></div>
					</div>
					<div class="perc-indicator skeleton"></div>
				</div>
				<div class="perc-row">
					<div class="perc-indicator skeleton"></div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-home"></div>
					</div>
					<div class="perc-label">
						<p>Over 3.5</p>
					</div>
					<div class="perc-value">
						<div class="skeleton skeleton-perc skeleton-perc-away"></div>
					</div>
					<div class="perc-indicator skeleton"></div>
				</div>
			</div>
			<div class="perc-row-holder" v-if="!isLoading">
				<percentageRowComponent :percentageHome="goalsData.home.over.btts"  :percentageAway="goalsData.away.over.btts" :label="'BTTS'"></percentageRowComponent>
				<percentageRowComponent :percentageHome="goalsData.home.over.bttsAndWin"  :percentageAway="goalsData.away.over.bttsAndWin" :label="'BTTS & Win'"></percentageRowComponent>
				<percentageRowComponent :percentageHome="goalsData.home.over.over1" :percentageAway="goalsData.away.over.over1" :label="'Over 0.5'"></percentageRowComponent>
				<percentageRowComponent :percentageHome="goalsData.home.over.over2" :percentageAway="goalsData.away.over.over2" :label="'Over 1.5'"></percentageRowComponent>
				<percentageRowComponent :percentageHome="goalsData.home.over.over3" :percentageAway="goalsData.away.over.over3" :label="'Over 2.5'"></percentageRowComponent>
				<percentageRowComponent :percentageHome="goalsData.home.over.over4" :percentageAway="goalsData.away.over.over4" :label="'Over 3.5'"></percentageRowComponent>
			</div>
		</div>
		<div class="goals-stats">
			<div class="greyheader">
				<div class="greyheader-value">
					<p>Scored</p>
				</div>
				<div class="greyheader-value">
					<p>Conceded</p>
				</div>
				<div class="greyheader-label">
					<p class="title">Avg. last</p><p class="limit-indicator">{{this.limit}}</p>
				</div>
				<div class="greyheader-value">
					<p>Scored</p>
				</div>
				<div class="greyheader-value">
					<p>Conceded</p>
				</div>
			</div>
			<div v-if="isLoading">
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>Total</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>Home | Away</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>1st Half</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>2nd Half</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
			</div>
			<div v-if="!isLoading">
				<statsRowComponent :scoredHome="goalsData.home.scored.total" :scoredAway="goalsData.away.scored.total" :concededHome="goalsData.home.conceded.total" :concededAway="goalsData.away.conceded.total" :label="'Total'"></statsRowComponent>
				<statsRowComponent :scoredHome="goalsData.home.scored.home" :scoredAway="goalsData.away.scored.away" :concededHome="goalsData.home.conceded.home" :concededAway="goalsData.away.conceded.away" :label="'Home | Away'"></statsRowComponent>
				<statsRowComponent :scoredHome="goalsData.home.scored.firstHalf" :scoredAway="goalsData.away.scored.firstHalf" :concededHome="goalsData.home.conceded.firstHalf" :concededAway="goalsData.away.conceded.firstHalf" :label="'1st Half'"></statsRowComponent>
				<statsRowComponent :scoredHome="goalsData.home.scored.secondHalf" :scoredAway="goalsData.away.scored.secondHalf" :concededHome="goalsData.home.conceded.secondHalf" :concededAway="goalsData.away.conceded.secondHalf" :label="'2nd Half'"></statsRowComponent>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css">
/* @import '../assets/css/startpage.css'; */
	.crd-tab > div:not(.spinner) {
		display: none;
	}

	/* Show the spinner when isLoading is true */
	.crd-tab .spinner {
		display: block;
	}
</style>
